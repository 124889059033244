import logo from './helppage.png';
import './App.css';

function App() {
  return (
    <section className="App">
      <h1 className="header">
        Instant Ipsum
      </h1>
      <div className="intro">
        ⚡&nbsp;&nbsp;Generate Lorem Ipsum in Chrome, with just a few keystrokes.<br/><br/>
        🤖&nbsp;&nbsp;Instantly fill text fields on supported websites with Lorem Ipsum.<br/><br/>
        ⌨️&nbsp;&nbsp;Puts Lorem Ipsum right into your clipboard, ready to paste anywhere.<br/>
      </div>
      <div>
        <img width="1080px" src={"./instantIpsum-1.gif"}/>
      </div>
      <div>
        <h2>Using Instant Ipsum</h2>
        <p>
          By default, this extension tries to use <b>Ctrl-Shift-8</b>, or <b>Command-Shift-8</b> (Mac).<br/><br/>
          If that keyboard shortcut is already assigned in your system, you will need to assign one to Instant Ipsum.<br/><br/>
          Check your Chrome keyboard shortcuts by going to <i>chrome://extensions/shortcuts</i> in your Chrome browser. Make sure you have a keyboard shortcut assigned or this extension won't work!<br/><br/>
          You can also simply pin and click on the Instant Ipsum icon in Chrome to open and start using it.
        </p>

        <img src={"./shortcut.png"}></img>
      </div>
      <div>
        <p>1. Use the keyboard shortcut to open the popup.</p>
        <p><b>Ctrl-Shift-8</b> or <b>Command-Shift-8</b> (Mac)</p>

        <img src={"./popup1.png"}></img>
        <p>2. To generate <i>words</i>, press any key from <b>1</b> to <b>9</b>.</p>
        <p>To generate <i>paragraphs</i>, press <b>p</b>. Then select the number of paragraphs from <b>1</b> to <b>9</b>.</p>
        <p>3. If your cursor is in a compatible text field in your browser, randomly generated Lorem Ipsum will be automatically added to the text field.</p>
        <p>For text fields that are <i>not</i> compatible, you can paste the text using <b>Ctrl-V</b> or <b>Command-V</b>. When you use this extension, the generated Lorem Ipsum is always automatically added to your clipboard.</p>
      </div>
      <div>
        <h2>Support & More</h2>
        <p>For help, questions, feature requests or just to chat, please email Jay at <a href="mailto:info@megabuildlab.com?subject=Instant Ipsum" target="_blank">info@megabuildlab.com.</a></p>
      </div>
      <div className="footer">
        Version 1.1<br/>
        Copyright 2021 Mega Build Lab Ltd. All Rights Reserved.
      </div>
    </section>
  );
}

export default App;
